import React from 'react';
import { Helmet } from 'react-helmet';

// Layout component should come first in imports
// since it holds global styles and css reset which should be applied first
import Layout from 'components/Layout';
import Seo from 'components/seo';
import useExternalScript from 'hooks/useExternalScript';

import { isInProduction } from 'utils/isInProduction';

const scriptUrl = isInProduction
  ? 'https://forms.vorsorgeplaner.online/vpFormsEmbed.min.js'
  : 'https://dev.forms.vorsorgeplaner.online/vpFormsEmbed.min.js';

const FuneralPlan = () => {
  useExternalScript(scriptUrl, {
    'data-container-id': 'bv-form'
  });

  return (
    <>
      <Helmet>
        <script>
          {`var vp_config = {
            agent: {
              user_id: 277,
              expert_image_url: 'https://convela.cloud/backend/storage/images/g0nfYmprfZM9Zsb2qq9Q2pZF8SyWU6mvVjRbnYey.png'
            },
            services: {
              chat: 'b6vhs3hq7o13rotxbbtgdvzlnv1vrmtm'
            },
            selected_form: 'bestattungsverfuegung',
            theme: {
              header_image: 'https://about.convela.cloud/static/hero3-8c9543e7f04f7433869fe3e399c437a4.jpeg',
              title_color: '#ffffff',
              tint_color: '#773d52',
              button_box_shadow: false
            },
            payload: {
              configParam1: 500,
              configParam2: 'lorem'
            }
          }

          var searchParams = new URLSearchParams(window.location.search);
          var params = Object.fromEntries(searchParams.entries());

          Object.assign(window.vp_config.payload, params);

          if (params.invite_code) {
            window.vp_config.agent.invite_code = params.invite_code;
          }
        `}
        </script>
      </Helmet>

      <Layout>
        <Seo
          title="Bestattungsverfügung kostenlos online erstellen"
          description="Erstellen Sie jetzt Ihre Bestattungsverfügung kostenlos online und sichern Sie sich die Gewissheit, dass Ihre letzten Wünsche erfüllt werden. Unser einfach zu bedienender Online-Service ermöglicht es Ihnen, Ihre Bestattungsverfügung bequem von zu Hause aus zu erstellen. Nutzen Sie jetzt die Gelegenheit und erstellen Sie Ihre Bestattungsverfügung kostenlos online."
        />
        <div id="bv-form" />
      </Layout>
    </>
  );
};

export default FuneralPlan;
