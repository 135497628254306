import { useEffect } from 'react';

const useExternalScript = (scriptUrl, attrubutes) => {
  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');

    Object.entries(attrubutes).forEach(([attributeName, attributeValue]) => {
      script.setAttribute(attributeName, attributeValue);
    });

    script.setAttribute('src', scriptUrl);
    head.appendChild(script);

    return () => {
      head.removeChild(script);
    };
  }, [scriptUrl]);
};

export default useExternalScript;
